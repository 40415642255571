import { API_URL } from "../config";

export const callApi = async (url, method, body) => {
  try {
    const response = await fetch(url, {
      method: method,
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(body),
    });

    return response;
  } catch (error) {
    console.error(`Failed to call ${url} API:`, error);
    throw error;
  }
};

export const getNewWebAccessToken = async (email, webRefreshToken) => {
  const newTokenResponse = await callApi(`${API_URL}/generate-new-token`, "POST", { email: email, refreshToken: webRefreshToken, type: 'web' });

  if (newTokenResponse.ok) {
    const newTokenJson = await newTokenResponse.json();
    return newTokenJson.webAccessToken;
  } else {
    return null;
  }
};

export const generatePairingCode = async (auth, pairingCode) => {
  const token = await localStorage.getItem('WebAccessToken')
  const data = {
    auth: auth,
    pairingCode: pairingCode,
    token: token,
    email: auth.email,
  }
  const generatePairingCodeRes = await callApi(`${API_URL}/generate-pairing-code`, "POST", data);

  // Make sure jwt is valid
  if (generatePairingCodeRes.status === 401) {
    const responseJson = await generatePairingCodeRes.json();
    if (responseJson.message === "Invalid token") {
      const newWebAccessToken = await getNewWebAccessToken(auth.email, auth.webRefreshToken);

      if (newWebAccessToken) {
        await localStorage.setItem("WebAccessToken", newWebAccessToken);
        return await generatePairingCode(auth, pairingCode);
      } else {
        logout();
        return false;
      }
    }
  }
};

export const updateUser = async (user) => {
  const email = user.email
  const token = await localStorage.getItem('WebAccessToken')
  const WebRefreshToken = await localStorage.getItem('WebRefreshToken')

  user.token = token
  user.refreshToken = WebRefreshToken

  const requestOptions = {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify(user)
  }

  const response = await fetch(API_URL + '/update-user', requestOptions)
    .then(response => response.json())
    .then(data => data)
    .catch(e => false)

  if (response.status === 401) {
    const responseJson = await response.json();
    if (responseJson.message === "Invalid token") {
      const newWebAccessToken = await getNewWebAccessToken(email, WebRefreshToken);
      
      if (newWebAccessToken) {
        await localStorage.setItem("WebAccessToken", newWebAccessToken);
        return await updateUser(user);
      } else {
        logout();

        return false
      }
    }
  }

  return true
};

export const deleteUser = async (user) => {
  const email = user.email
  const token = await localStorage.getItem('WebAccessToken')
  const WebRefreshToken = await localStorage.getItem('WebRefreshToken')

  user.token = token
  user.refreshToken = WebRefreshToken

  const requestOptions = {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify(user)
  }

  const response = await fetch(API_URL + '/delete-user', requestOptions)
    .then(response => response.json())
    .then(data => data)
    .catch(e => false)

  if (response.status === 401) {
    const responseJson = await response.json();
    if (responseJson.message === "Invalid token") {
      const newWebAccessToken = await getNewWebAccessToken(email, WebRefreshToken);

      if (newWebAccessToken) {
        await localStorage.setItem("WebAccessToken", newWebAccessToken);
        return await updateUser(user);
      } else {
        logout();

        return false
      }
    }
  }

  return true
};

export const getUsers = async (auth) => {
  if (!auth) {
    return false
  }
  const email = auth.email
  const token = await localStorage.getItem('WebAccessToken')
  const WebRefreshToken = await localStorage.getItem('WebRefreshToken')
  auth.token = token
  auth.webRefreshToken = WebRefreshToken
  auth.type = 'web'

  const requestOptions = {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify(auth)
  }

  const response = await fetch(API_URL + '/get-users', requestOptions)
    .then(response => response.json())
    .then(data => data)
    .catch(e => false)

  if (response.message === "Invalid token") {
    logout();
  }

  return response
};

export const submitHelpRequest = async (type, email, child, message) => {
  try {
    const token = await localStorage.getItem('WebAccessToken')
    const data = {
      message: message,
      type: type,
      token: token,
      email: email,
      child: child,
    };

    const response = await callApi(`${API_URL}/help-request`, "POST", data);

    if (response.message === "Invalid token") {
      logout();
    }

    return true;
  } catch (error) {
    console.error("Failed to send help request:", error);
    return false;
  }
};

export const logout = async () => {
  localStorage.removeItem('children');
  localStorage.removeItem('parent');
  localStorage.removeItem('setupStep');
  localStorage.removeItem('isLoggedIn');
  localStorage.removeItem('WebAccessToken');
  localStorage.removeItem('WebRefreshToken');

  if (window.google && window.google.accounts) {
    window.google.accounts.id.disableAutoSelect();
  }

  window.location.href = "/login";
}

export const emailUnsubscribe = async (token) => {
  const data = {
    token: token,
  };

  const response = await callApi(`${API_URL}/email-unsubscribe`, "POST", data);

  return response;
}

export const createGroup = async (group) => {
  const email = group.ownerUsername
  const token = await localStorage.getItem('WebAccessToken')
  const WebRefreshToken = await localStorage.getItem('WebRefreshToken')

  let body = {
    group: group,
    token: token,
    email: email,
  } 

  const requestOptions = {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify(body)
  }

  const response = await fetch(API_URL + '/create-group', requestOptions)

  const responseJson = await response.json();

  if (response.status === 401) {
    const responseJson = await response.json();

    if (response.message === "Invalid token") {
      logout();
    }
  }

  return true
}

export const sendGroupOffer = async (emails, ownerEmail) => {
  const body = {
    emails: emails,
    ownerEmail: ownerEmail,
    ardn: '65229559201S'
  };

  const requestOptions = {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify(body),
  };

  const response = await fetch(API_URL + '/send-group-offer', requestOptions)

  if (response.status === 401) {
    return {message: 'no offer codes left'};
  }

  return true;
};

export const getGroupOwner = async (email, startKey = 0) => {
  const body = {
    email: email,
    ardn: '65229559201S',
    startKey: startKey
  };

  const requestOptions = {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify(body),
  };

  const response = await fetch(API_URL + '/get-group-owner', requestOptions)

  const res = await response.json();

  if (!res.groupOwner) {
    return false;
  }

  return res.groupOwner;
}

export const createGroupOwner = async (email, name, offerCodeCount) => {
  const body = {
    email: email,
    name: name,
    offerCodeCount: offerCodeCount,
    ardn: '65229559201S'
  };

  const requestOptions = {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify(body),
  };

  const response = await fetch(API_URL + '/create-group-owner', requestOptions)

  const res = await response.json();

  return res;
}

export const updateGroupOwner = async (group) => {
  let body = {
    group: group,
    ardn: '65229559201S'
  } 

  const requestOptions = {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify(body)
  }

  const response = await fetch(API_URL + '/update-group-owner', requestOptions)

  const test = await response.json();

  return true
}

export const joinGroup = async (email, groupCode, allowUninvited = false) => {
  const body = {
    email: email,
    code: groupCode,
    allowUninvited: allowUninvited,
  };

  const requestOptions = {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify(body),
  };

  const response = await fetch(API_URL + '/join-group', requestOptions)

  const res = await response.json();

  return res;
}

export const insertPromoCode = async (email, promoCode) => {
  const body = {
    email: email,
    code: promoCode,
    ardn: '65229559201S'
  };

  const requestOptions = {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify(body),
  };

  const response = await fetch(API_URL + '/insert-promo', requestOptions)

  const res = await response.json();

  return res;
}

export const createAffiliate = async (email) => {
  const body = {
    email: email,
    ardn: '65229559201S'
  };

  const requestOptions = {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify(body),
  };

  const response = await fetch(API_URL + '/create-affiliate', requestOptions)

  const res = await response.json();

  return res;
}

export const emailAffiliateRequest = async (email) => {
  const body = {
    email: email,
    ardn: '65229559201S'
  };

  const requestOptions = {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify(body),
  };

  const response = await fetch(API_URL + '/email-affiliate-request', requestOptions)

  const res = await response.json();

  return res;
}

export const getBlogPosts = async (limit, lastKey) => {
  let url = `${API_URL}/get-posts?limit=${limit}`;

  if (lastKey) {
    url += `&lastKey=${lastKey}`;
  }

  const response = await fetch(url);
  
  const res = await response.json();

  return {
    posts: res?.posts,
    lastEvaluatedKey: res?.lastKey
  };
}

export const getBlogPostById = async (postId) => {
  const response = await fetch(API_URL + '/get-post-by-id/' + postId)
  
  const res = await response.json();

  const post = res?.post[0];

  post.tags = post?.tags?.split(',');

  return post;
}

export const searchBlogPosts = async (term) => {
  const response = await fetch(API_URL + '/search-posts/' + term)
  
  const res = await response.json();

  return {
    posts: res?.posts,
    lastEvaluatedKey: res?.lastKey
  };
}

export const newsletterSignup = async (email) => {
  const body = {
    email: email,
  };

  const requestOptions = {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify(body),
  };

  const response = await fetch(API_URL + '/newsletter-signup', requestOptions)

  const res = await response.json();

  if (response.status === 200) {
    return {
      success: true,
    }
  }

  return res;
}

export const cancelStripeSubscription = async (groupOwner) => {
  const body = {
    groupOwner: groupOwner,
    ardn: '65229559201S'
  };

  const requestOptions = {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify(body),
  };

  const response = await fetch(API_URL + '/cancel-stripe-subscription', requestOptions)

  if (response.status === 200) {
    return true
  }

  return false;
}


export const requestFeedback = async (email) => {
  const body = {
    email: email,
    ardn: '65229559201S'
  };

  const requestOptions = {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify(body),
  };

  const response = await fetch(API_URL + '/feedback-request', requestOptions)

  console.log(response)

  if (response.status === 200) {
    return true
  }

  return false;
}

/**
 * Emails non subscribed parents created in the last 24-48 hours, to request feedback
 */
export const autoRequestFeedback = async () => {
  const body = {
    ardn: '65229559201S'
  };

  const requestOptions = {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify(body),
  };

  const response = await fetch(API_URL + '/auto-feedback-request', requestOptions)

  console.log(response)

  if (response.status === 200) {
    return true
  }

  return false;
}

export const sendFeedback = async (feedback) => {

  const requestOptions = {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({
      feedback: feedback,
      ardn: '65229559201S'
    }),
  };

  const response = await fetch(API_URL + '/feedback-success', requestOptions)

  if (response.status === 200) {
    return true
  }

  return false;
}