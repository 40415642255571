import React from 'react';
import '../css/animations.css';
import { backgroundColor, orange } from '../Colors';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';

export const LoadingScreen = () => {
  const loadingStyle = {
    width: '100vw',
    height: '50vh',
    minHeight: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: backgroundColor, 
    position: 'absolute',
    top: 0,
    left: 0,
    zIndex: 1000,
  };

  return (
    <div style={loadingStyle}>
      <FontAwesomeIcon className="spinner" color={orange} icon={faSpinner} spin size="3x" />
    </div>
  );
};

export default LoadingScreen;
