import React, { createContext, useContext, useReducer, useEffect } from 'react';

const UserContext = createContext();

const initialState = {
  children: localStorage.getItem('children') ? JSON.parse(localStorage.getItem('children')) : [],
  parent: localStorage.getItem('parent') ? JSON.parse(localStorage.getItem('parent')) : {},
  isLoggedIn: localStorage.getItem('isLoggedIn') === 'true' ? true : false,
  groupOwner: localStorage.getItem('groupOwner') ? JSON.parse(localStorage.getItem('groupOwner')) : {}
};

const userReducer = (state, action) => {
  switch (action.type) {
    case 'SET_CHILDREN':
      return { ...state, children: action.payload };
    case 'SET_PARENT':
      return { ...state, parent: action.payload };
    case 'SET_GROUP_OWNER':
      return { ...state, groupOwner: action.payload };
    case 'login':
      return { ...state, isLoggedIn: true };
      case 'logout':
        localStorage.removeItem('children');
        localStorage.removeItem('parent');
        localStorage.removeItem('isLoggedIn');
        localStorage.removeItem('WebAccessToken');
        localStorage.removeItem('pairingCode');
        localStorage.removeItem('groupOwner');
      return { ...initialState, isLoggedIn: false };
    default:
      return state;
  }
};

export const UserProvider = ({ children }) => {
  const [state, dispatch] = useReducer(userReducer, initialState);

  // Load state from local storage
  useEffect(() => {
    if (state.isLoggedIn) {
      localStorage.setItem('children', JSON.stringify(state.children));
      localStorage.setItem('parent', JSON.stringify(state.parent));
      localStorage.setItem('isLoggedIn', state.isLoggedIn);
    } else {
      localStorage.removeItem('children');
      localStorage.removeItem('parent');
      localStorage.removeItem('isLoggedIn');
      localStorage.removeItem('WebAccessToken');
      localStorage.removeItem('pairingCode');
      localStorage.removeItem('groupOwner');
    }
  }, [state.isLoggedIn, state.children, state.parent]);

  const setChildren = (children) => {
    localStorage.setItem('children', JSON.stringify(children));
    dispatch({ type: 'SET_CHILDREN', payload: children });
  };

  const setParent = (parent) => {
    let sanitizedParent = sanitizeParent(parent);
    localStorage.setItem('parent', JSON.stringify(sanitizedParent));
    dispatch({ type: 'SET_PARENT', payload: sanitizedParent });
  };

  const setGroupOwner = (groupOwner) => {
    localStorage.setItem('groupOwner', JSON.stringify(groupOwner));
    dispatch({ type: 'SET_GROUP_OWNER', payload: groupOwner });
  }

  const sanitizeParent = (parent) => {
    const {
      WebAccessToken,
      password,
      verificationToken,
      resetPasswordExpires,
      resetPasswordToken,
      ...sanitizedParent
    } = parent;
    return sanitizedParent;
  };  

  const setIsLoggedIn = (loggedIn) => {
    localStorage.setItem('isLoggedIn', loggedIn);
    dispatch({ type: 'login' });
  };

  const logout = () => {
    try {
      dispatch({ type: 'logout' });
    } catch (error) {
      console.error('Failed to log out:', error);
    }
  };

  return (
    <UserContext.Provider
      value={{
        state,
        setChildren,
        setIsLoggedIn,
        setParent,
        setGroupOwner,
        logout,
      }}
    >
      {children}
    </UserContext.Provider>
  );
};

export const useUser = () => {
  return useContext(UserContext);
};

export { UserContext };