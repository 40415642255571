import React from 'react';
import { useTranslation } from 'react-i18next';
import '../css/pastor-endorsement.css';

export const PastorEndorsement = () => {
  const { t } = useTranslation();
  const isMobile = window.innerWidth < 768;

  let src = '/images/home/ribbon.webp';

  if (isMobile) {
    src = '/images/home/ribbon-mobile.webp';
  }

  return (
    <div className='pastor-container'>
      <img height={isMobile ? '45px' : '75px'} width={isMobile ? '45px' : '75px'} className="ribbon" src={src} alt="Ribbon"/>
      <p className='pastor-description'>{t('pastorEndorsement:description')}</p>
    </div>
  );
};

export default PastorEndorsement;
