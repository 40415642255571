import React, { useState, useEffect, useRef } from 'react';
import { NavLink, useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import { useUser } from '../store/user-context';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBars } from '@fortawesome/free-solid-svg-icons';
import { textColor } from '../Colors';
import '../css/nav.css';
import { useTranslation } from 'react-i18next';

const StyledNav = styled.nav`
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: white;
  padding: 0.5rem 1rem;
  height: 80px;
  border-width: 0px;
  border-style: solid;
  border-color: #ccc;
  padding-left: 25px;
  padding-right: 25px;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 500;
  width: 100%;
  box-sizing: border-box;

  @media screen and (max-width: 1200px) {
    background-color: transparent;
  }
`;

const NavList = styled.div`
  display: flex;
  list-style: none;
  margin: 0;
  padding: 0;
  align-items: center;
  justify-content: space-between;
  width: 100%;
`;

const Logo = styled.img`
  // width: 50px;
  // height: auto;
  padding-right: 0.5rem;
`;

const LogoTitle = styled.p`
  font-size: 1.5rem;
  margin: 0px;
  font-family: 'Roboto-Bold', arial, sans-serif;

  @media screen and (max-width: 900px) {
    // display: none;
  }
`;

const LogoContainer = styled.div`
  display: flex;
  align-items: center;
  position: absolute;
  left: 50%;
  top: 40px;
  transform: translate(-50%, -50%);
  cursor: pointer;
  &:active, &:focus {
    outline: none;
  }
`;

const LogoutButton = styled.button`
  background: none;
  border: none;
  cursor: pointer;
  padding: 0;
  margin: 0;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
  color: inherit;
  text-align: left;
`;
const MenuButton = styled.button`
  display: none;
  background-color: transparent;
  border: none;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
  margin: 0;
  position: relative;
  z-index: 200;

  &:active, &:focus {
    outline: none;
  }

  /* Update the media query */
  @media screen and (max-width: 1200px) {
    display: flex;
    padding: 10px;
    -webkit-tap-highlight-color: transparent;
    &:active, &:focus {
      outline: none;
    }
  }
`;

const MobileMenu = styled.div`
  display: none;
  flex-direction: column;
  background-color: white;
  position: absolute;
  top: 70px;
  padding: 0px;
  right: 0px;
  box-shadow: 0 2px 2px rgba(0, 0, 0, 0.1);
  border-radius: 5px;
  z-index: 100;
  border: 1px solid #ccc;
  padding-top: 0px;
  overflow: hidden;

  @media screen and (max-width: 1200px) {
    display: ${props => (props.open ? 'flex' : 'none')};
  }
`;

const Links = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;

  /* Update the media query */
  @media screen and (max-width: 1200px) {
    display: none;
  }
`;

const LoggedInNav = styled.div`
  @media screen and (max-width: 1200px) {
    display: none;
  }
`;

const NavItem = styled.span`
  margin-right: 1rem;
  margin-left: 1rem;
  list-style: none;
  padding: 8px 0;

  @media (max-width: 1200px) {
    margin-right: 0px;
    margin-left: 0px;
  }
`;

const Nav = () => {
  const { logout, state } = useUser();
  const { isLoggedIn, groupOwner, parent } = state;
  const navigate = useNavigate();
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false);
  const [isScrolled, setIsScrolled] = useState(false);
  const isMobileOrTablet = window.innerWidth <= 1200;
  const { t } = useTranslation('nav');
  const isOnGroupMembershipPage = window.location.pathname === '/group-memberships';
  // const showLoggedInNav = (isLoggedIn && (!isOnGroupMembershipPage && !parent) && ((parent && parent.email) || (groupOwner && groupOwner.email))); // cant figure out login issue
  const showLoggedInNav = (isLoggedIn && ((parent && parent.email) || (groupOwner && groupOwner.email)));

  const handleLogout = () => {
    logout();
    navigate('/login');
  };

  const toggleMobileMenu = () => {
    const open = mobileMenuOpen ? false : true;
    setMobileMenuOpen(open);
  };

  // Add a useRef hook to reference the mobile menu
  const mobileMenuRef = useRef(null);
  const menuButtonRef = useRef(null);

  // Add this function to handle clicks outside the mobile menu
  const handleClickOutside = (event) => {
    if (mobileMenuRef.current && !mobileMenuRef.current.contains(event.target) && !menuButtonRef.current.contains(event.target)) {
      setMobileMenuOpen(false);
    }
  };

  // Add a useEffect hook to add and remove the event listener
  useEffect(() => {
    const checkScroll = () => setIsScrolled(window.scrollY > 0);
  
    window.addEventListener("scroll", checkScroll);
    document.addEventListener('mousedown', handleClickOutside);

    return () => {
      window.removeEventListener("scroll", checkScroll);
      document.removeEventListener('mousedown', handleClickOutside);
    };
    if (isMobileOrTablet) {
    }
  }, [isMobileOrTablet, handleClickOutside]);

  const goHome = () => {
    navigate('/');
  };

  const scrollStyle = { 
    borderBottom: '1px solid #F5F5F5',
    backgroundColor: 'white'
  }
  
  const noScrollStyle = {
    borderBottom: '1px solid #F5F5F5'
  }

  if (['/', '/download-app', '/account', '/memberships', '/group', '/group-memberships', '/affiliate'].includes(window.location.pathname)) {
    noScrollStyle.borderBottom = 'none';
    noScrollStyle.backgroundColor = 'white';
  }

  return (
    <StyledNav style={isScrolled ? scrollStyle : noScrollStyle}>
      <MobileMenu open={mobileMenuOpen} ref={mobileMenuRef}>
        <NavItem>
          <NavLink className={({ isActive }) => isActive ? "nav-link-active" : "nav-link"} to="/" onClick={toggleMobileMenu}>
            {t('aboutUs')}
          </NavLink>
        </NavItem>
        {/* <NavItem>
          <NavLink className={({ isActive }) => isActive ? "nav-link-active" : "nav-link"} to="/group" onClick={toggleMobileMenu}>
            {t('groupPlans')}
          </NavLink>
        </NavItem> */}
        <NavItem>
          <NavLink className={({ isActive }) => isActive ? "nav-link-active" : "nav-link"} to="/blog" onClick={toggleMobileMenu}>
            {t('blog')}
          </NavLink>
        </NavItem>
        {showLoggedInNav ? (
          <>
            {
              (groupOwner && groupOwner.email) && (
                <NavItem>
                  <NavLink className={({ isActive }) => isActive ? "nav-link-active" : "nav-link"} to="/group-dashboard" onClick={toggleMobileMenu}>
                    {t('groupDashboard')}
                  </NavLink>
                </NavItem>
              ) 
            }
            <NavItem>
              <NavLink className={({ isActive }) => isActive ? "nav-link-active" : "nav-link"} to="/account" onClick={toggleMobileMenu}>
                {t('account')}
              </NavLink>
            </NavItem>
            <NavItem>
                <LogoutButton onClick={() => { handleLogout(); toggleMobileMenu(); }} className={({ isActive }) => isActive ? "nav-link-active" : "nav-link"}>
                <NavLink className={"nav-link"} as="span">
                  {t('logout')}
                </NavLink>
              </LogoutButton>
            </NavItem>
          </>
        ) : (
          <>
            <NavItem>
              <NavLink className={({ isActive }) => isActive ? "nav-link-active" : "nav-link"} to="/login" onClick={toggleMobileMenu}>
                {t('login')}
              </NavLink>
            </NavItem>
          </>
        )}
      </MobileMenu>
      <NavList className="link-container">
        <Links >
          <NavItem>
            <NavLink className={({ isActive }) => isActive ? "nav-link-active" : "nav-link"} to="/">
              {t('aboutUs')}
            </NavLink>
          </NavItem>
          {/* <NavItem>
            <NavLink className={({ isActive }) => isActive ? "nav-link-active" : "nav-link"} to="/group">
              {t('groupPlans')}
            </NavLink>
          </NavItem> */}
          <NavItem>
            <NavLink className={({ isActive }) => isActive ? "nav-link-active" : "nav-link"} to="/blog">
              {t('blog')}
            </NavLink>
          </NavItem>
        </Links>
        <span>
          <LogoContainer onClick={() => goHome()}>
            {
              isMobileOrTablet ? (
                <Logo width="50px" height="56.7px" src='/images/logo/logo-small.webp' alt="BibleLimits Logo" />
              ) : (
                <Logo width="50px" height="56.7px" src='/images/logo/logo.webp' alt="BibleLimits Logo" />
              )
            }
            <LogoTitle>BibleLimits</LogoTitle>
          </LogoContainer>
        </span>
        <LoggedInNav>
          {showLoggedInNav ? (
            <NavList>
              {
                (groupOwner && groupOwner.email) && (
                  <NavItem>
                    <NavLink className={({ isActive }) => isActive ? "nav-link-active" : "nav-link"} to="/group-dashboard">
                      {t('groupDashboard')}
                    </NavLink>
                  </NavItem>
                )
              }
              <NavItem>
                {
                  ((parent && parent.email) || (groupOwner)) && (
                    <NavLink className={({ isActive }) => isActive ? "nav-link-active" : "nav-link"} to="/account">
                      {t('account')}
                    </NavLink>
                  )
                }
              </NavItem>
              <NavItem>
                <LogoutButton onClick={handleLogout}>
                  <NavLink className={"nav-link"} as="span">
                    {t('logout')}
                  </NavLink>
                </LogoutButton>
              </NavItem>
            </NavList>
          ) : (
            <NavList>
              <NavItem>
                <NavLink className={({ isActive }) => isActive ? "nav-link-active" : "nav-link"} to="/login">
                  {t('login')}
                </NavLink>
              </NavItem>
            </NavList>
          )}
        </LoggedInNav>
      </NavList>
      <MenuButton 
        ref={menuButtonRef}
        onClick={toggleMobileMenu}
        title='Menu'
        aria-label='Menu'
      >
        <FontAwesomeIcon icon={faBars} size='2x' color={textColor} />
      </MenuButton>
    </StyledNav>
  );  
};

export default Nav;