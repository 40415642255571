import React, { Component } from "react";
import Slider from "react-slick";
import '../css/image-slider.css';

export default class QuizSlider extends Component {
  render() {
    const settings = {
      dots: true,
      infinite: true,
      speed: 500,
      slidesToShow: 3,
      slidesToScroll: 1,
      initialSlide: 0,
      autoplay: true,
      speed: 2000,
      autoplaySpeed: 4000,
      lazyLoad: true,
      responsive: [
        {
          breakpoint: 1024,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
            initialSlide: 1
          }
        },
        {
          breakpoint: 480,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1
          }
        }
      ]
    };

    return (
      <div style={{ width: '100%', overflow: 'hidden', backgroundColor: '#E0F7FA'}} className="quiz-slider">
        <p style={{fontWeight: 300}} className="quiz-slider-header">Explore Our Interactive Bible Quizzes</p>
        <Slider {...settings}>
          <div>
            <div className="quiz-card-container">
              <img
                className="quiz-card"
                width="380px"
                height="527px"
                src='https://d1h45vdk7ivw2w.cloudfront.net/quiz/who.webp'
                alt="Quiz question for BibleLimits on guessing the person of a Bible verse."
                loading="lazy"
                />
            </div>
          </div>
          <div>
            <div className="quiz-card-container">
              <img
                className="quiz-card"
                width="380px"
                height="301px"
                src='https://d1h45vdk7ivw2w.cloudfront.net/quiz/fill.webp'
                alt="Quiz question for BibleLimits on fill in the blank"
                loading="lazy"
              />
            </div>
          </div>
          <div>
            <div className="quiz-card-container">
              <img
                className="quiz-card"
                width="380px"
                height="549px"
                src='https://d1h45vdk7ivw2w.cloudfront.net/quiz/location.webp'
                alt="Quiz question for BibleLimits on guessing the location of a Bible verse."
                loading="lazy"
                />
            </div>
          </div>
          <div>
            <div className="quiz-card-container">
              <img
                className="quiz-card"
                src='https://d1h45vdk7ivw2w.cloudfront.net/quiz/mc.webp'
                alt="Quiz question for BibleLimits on multiple choice."
                loading="lazy"
                width="380px"
                height="580px"
                />
            </div>
          </div>
          <div>
            <div className="quiz-card-container">
              <img
                className="quiz-card"
                src='https://d1h45vdk7ivw2w.cloudfront.net/quiz/pickverse.webp'
                alt="Quiz question for BibleLimits on picking the correct verse."
                loading="lazy"
                width="380px"
                height="583px"
                />
            </div>
          </div>
          <div>
            <div className="quiz-card-container">
              <img
                className="quiz-card"
                src='https://d1h45vdk7ivw2w.cloudfront.net/quiz/retype.webp'
                alt="Quiz question for BibleLimits on retyping the verse."
                loading="lazy"
                width="380px"
                height="539px"
                />
            </div>
          </div>
        </Slider>
      </div>
    );
  }
}
