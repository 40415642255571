import React, { useState } from 'react';

const YoutubeEmbed = ({ embedId }) => {
  const [showVideo, setShowVideo] = useState(false);

  const screenWidth = window.innerWidth;
  const maxWidth = 800; // Set this to your desired max width
  const width = Math.min(screenWidth * 0.9, maxWidth);
  const aspectRatio = 9 / 16; // Aspect ratio of YouTube videos
  const src = '/images/home/youtubethumb.webp';
  
  const handlePlay = () => {
    setShowVideo(true);
  };

  if (showVideo) {
    return (
      <div style={{ display: 'flex', justifyContent: 'center' }}>
        <iframe
          width={width}
          height={width * aspectRatio}
          style={{borderRadius: '15px'}}
          src={`https://www.youtube.com/embed/${embedId}?autoplay=1`}
          title="YouTube video player"
          frameBorder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowFullScreen
        ></iframe>
      </div>
    );
  }

  return (
    <div onClick={handlePlay} style={{ display: 'flex', justifyContent: 'center', cursor: 'pointer' }}>
      <img
        width={width}
        height={width * aspectRatio}
        style={{borderRadius: '15px'}}
        alt="thumbnail"
        src={src}
      />
    </div>
  );  
};

export default YoutubeEmbed;