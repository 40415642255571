import { BrowserRouter as Router, Route, Routes, useNavigate } from 'react-router-dom';
import React, { Suspense } from 'react';
import Nav from './containers/Nav';
import { PrivateRoute } from './components/PrivateRoute';
import { UserProvider } from './store/user-context';
import './App.css'
import "slick-carousel/slick/slick.css"; 
import "slick-carousel/slick/slick-theme.css";
import { ScrollToTop } from './components/ScrollToTop';
import WavedFooter from './components/WavedFooter';
import usePageView from './usePageView';
import CookiePopup from './components/CookiePopup';
import { useEffect, useState } from 'react';
import Home from './containers/Home';
import Group from './containers/Group';
import LoadingScreen from './components/LoadingScreen';
import {AdminRoute} from './components/AdminRoute';

const Login = React.lazy(() => import('./containers/Login'));
const GroupSignup = React.lazy(() => import('./containers/GroupSignup'));
const GroupMemberships = React.lazy(() => import('./containers/GroupMemberships'));
const PromoSetup = React.lazy(() => import('./containers/PromoSetup'));
const BlogPage = React.lazy(() => import('./blog/BlogPage'));
const BlogPost = React.lazy(() => import('./blog/BlogPost'));
const DownloadNow = React.lazy(() => import('./containers/DownloadNow'));
const JoinGroup = React.lazy(() => import('./containers/JoinGroup'));
const HelpRequest = React.lazy(() => import('./containers/HelpRequest'));
const DeleteAccount = React.lazy(() => import('./containers/DeleteAccount'));
const Newsletter = React.lazy(() => import('./containers/Newsletter'));
const GroupDashboard = React.lazy(() => import('./containers/GroupDashboard'));
const UnsubscribeConfirm = React.lazy(() => import('./components/UnsubscribeConfirm'));
const AdminDashboard = React.lazy(() => import('./admin/AdminDashboard'));
const Account = React.lazy(() => import('./components/Account'));
const Guides = React.lazy(() => import('./containers/Guides'));
const ContactInfo = React.lazy(() => import('./containers/ContactInformation'));
const NotFound = React.lazy(() => import('./containers/NotFound'));
const PrivacyPolicy = React.lazy(() => import('./components/PrivacyPolicy'));
const Affiliate = React.lazy(() => import('./containers/Affiliate'));
const TermsOfUse = React.lazy(() => import('./components/TermsOfUse'));
const InviteToGroup = React.lazy(() => import('./containers/InviteToGroup'));
const ForgotPassword = React.lazy(() => import('./containers/ForgotPassword'));
const StoreRedirect = React.lazy(() => import('./containers/StoreRedirect'));
const VerifyEmail = React.lazy(() => import('./components/VerifyEmail'));
const ResetPassword = React.lazy(() => import('./containers/ResetPassword'));
const EmailUnsubscribe = React.lazy(() => import('./containers/EmailUnsubscribe'));
const Donate = React.lazy(() => import('./containers/Donate'));
const FeedbackForm = React.lazy(() => import('./containers/FeedbackForm'));
const TwitterCallback = React.lazy(() => import('./containers/TwitterCallback'));

const FAQ = React.lazy(() => import('./containers/FAQ'));

function App() {
  const [cookiesAccepted, setCookiesAccepted] = useState(
    localStorage.getItem('cookieConsent') === 'accepted'
  );

  const handleCookieAccept = () => {
    setCookiesAccepted(true);
    loadGoogleAnalytics();

    if (window.gtag) {
      window.gtag('consent', 'update', {
        'ad_user_data': 'granted',
        'ad_personalization': 'granted',
        'ad_storage': 'granted',
        'analytics_storage': 'granted'
      });
    }
  };

  const loadGoogleAnalytics = () => {
    // if its already loaded, don't load it again
    if (window.gtag) {
      return;
    }

    setTimeout(() => {
      const script = document.createElement('script');
      script.src = 'https://www.googletagmanager.com/gtag/js?id=G-EMT79Z57JS';
      script.async = true;
      document.head.appendChild(script);
      script.onload = () => {
        window.dataLayer = window.dataLayer || [];
        window.gtag = function() { window.dataLayer.push(arguments); };
        window.gtag('js', new Date());
        window.gtag('config', 'G-EMT79Z57JS');
      };
    }, 3500);
  };

  const loadHubSpot = () => {
    // if it's already loaded, don't load it again
    if (document.getElementById('hs-script-loader')) {
      return;
    }
  
    setTimeout(() => {
      const script = document.createElement('script');
      script.src = '//js.hs-scripts.com/45383518.js';
      script.id = 'hs-script-loader';
      script.async = true;
      script.defer = true;
      document.head.appendChild(script);
    }, 4000);
  };

  useEffect(() => {
    // if (cookiesAccepted) {
    // }
    handleCookieAccept();
    loadGoogleAnalytics();
    // loadHubSpot();

  }, [cookiesAccepted]);

  return (
    <Router>
      <PageViewTracker cookiesAccepted={cookiesAccepted} />
      <ScrollToTop />
      <div className="app-main">
        <CookiePopup onAccept={handleCookieAccept} />
        <UserProvider>
          <Nav />
          <Suspense fallback={<LoadingScreen />}>
            <Routes>
              <Route path="/login" element={<Login key="login" />} />
              <Route path="/*" element={<NotFound />} />
              <Route path="/" element={<Home />} index />
              <Route path="/blog" element={<BlogPage />} />
              <Route path="/blog/:postId" element={<BlogPost />} />
              <Route path="/download-now" element={<DownloadNow />} />
              <Route path="/promo" element={<PromoSetup />} />
              <Route path="/group" element={<Group />} />
              <Route path="/privacy-policy" element={<PrivacyPolicy />} />
              <Route path="/terms-of-use" element={<TermsOfUse />} />
              <Route path="/affiliate" element={<Affiliate />} />
              <Route path="/group-signup" element={<GroupSignup />} />
              <Route path="/faq" element={<FAQ />} />
              <Route path="/account" element={<PrivateRoute element={<Account />} />} />
              <Route path="/contact-info" element={<ContactInfo />} />
              <Route path="/forgot-password" element={<ForgotPassword />} />
              <Route path="/reset-password" element={<ResetPassword />} />
              <Route path="/guides" element={<Guides />} />
              <Route path="/join-group" element={<JoinGroup />} />
              <Route path="/invite-to-group" element={<InviteToGroup />} />
              <Route path="/donate" element={<Donate />} />
              <Route path="/download-app" element={<StoreRedirect />} />
              <Route path="/verify-email" element={<VerifyEmail />} />
              <Route path="/feedback-request" element={<FeedbackForm />} />
              <Route path="/twitter-callback-dev" element={<TwitterCallback />} />
              <Route path="/email-unsubscribe" element={<EmailUnsubscribe />} />
              <Route path="/delete-account" element={<PrivateRoute element={<DeleteAccount />} />} />
              <Route path="/unsubscribe" element={<PrivateRoute element={<UnsubscribeConfirm />} />} />
              <Route path="/group-dashboard" element={<PrivateRoute element={<GroupDashboard />} />} />
              <Route path="/group-memberships" element={<PrivateRoute element={<GroupMemberships />} />} />
              <Route path="/help-request" element={<PrivateRoute element={<HelpRequest />} />} />
              <Route path="/radio" element={<RedirectToHome utmSource="radio_ad" utmMedium="offline" />} />
              <Route path="/podcast" element={<RedirectToHome utmSource="niche_podcast" utmMedium="offline" />} />
              <Route path="/newsletter" element={<Newsletter />} />
              <Route path="/admin" element={<AdminRoute element={<AdminDashboard />} />} />
              <Route path="*" element={<NotFound />} />
            </Routes>
          </Suspense>
          <WavedFooter /> 
        </UserProvider>
      </div>
    </Router>
  );
}

const PageViewTracker = ({ cookiesAccepted }) => {
  usePageView(cookiesAccepted);
  return null;
};

const RedirectToHome = ({ utmSource, utmMedium }) => {
  const navigate = useNavigate();
  
  useEffect(() => {
    const urlWithUtm = `/?utm_source=${utmSource}&utm_medium=${utmMedium}`;
    navigate(urlWithUtm, { replace: true });
  }, [navigate, utmSource, utmMedium]);
  
  return null;
};


export default App;