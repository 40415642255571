import { useUser } from '../store/user-context';
import { Navigate } from 'react-router-dom';

export const AdminRoute = ({ element }) => {
  const { state } = useUser();
  const { isLoggedIn, parent } = state;

  const adminEmails = [
    'andrew.nelsonrd@gmail.com',
    'imbak333@gmail.com',
    'biblelimits.tester@biblelimits.com',
    'biblelimits.updates@biblelimits.com',
    'biblelimits.support@biblelimits.com',
  ];

  const isAdmin = parent && parent.email && adminEmails.includes(parent.email);

  // return element

  return (isLoggedIn && isAdmin ) ? element : <Navigate to="/404" replace />
};
