import React, { useEffect, useState, useMemo } from 'react';
import '../css/store-badges.css';

import { useLocation } from 'react-router-dom';
import { createGtmEvent, getClientId, getUserId } from '../utility/gtmHelper';

const StoreBadges = ({ customStyles }) => {
  const [cookies, setCookies] = useState(null);
  const isMobile = useMemo(() => window.innerWidth < 768, []);
  const location = useLocation();

  const appleUrl = 'https://apps.apple.com/us/app/biblelimits/id6462471354';
  const googleUrl = 'https://play.google.com/store/apps/details?id=com.biblelimits';

  useEffect(() => {
    async function fetchCookies() {
      const consent = await localStorage.getItem('cookieConsent');
      setCookies(consent);
    }
    fetchCookies();
  }, []);

  async function trackStoreClick(store, url) {
    // append utm parameters currently on the window, to the url
    const urlWithUtms = new URL(url);
    const params = new URLSearchParams(window.location.search);
    urlWithUtms.search = params.toString();
    console.log(urlWithUtms);

    console.log(params)
    console.log(window.gtag)
    // the utm_content param will have the a/b testing value. I need it.
    const utmContent = params.get('utm_content');

    if (window.gtag) {  
      const gtagPromise = new Promise((resolve, reject) => {
        try {
          window.gtag('event', 'download_click', {
            'event_category': 'Begin Checkout',
            'event_label': store,
            'value': urlWithUtms,
            'variant': utmContent,
          });
          console.log('gtag event sent');
          setTimeout(resolve, 200);
        } catch (error) {
          console.log(error)
          reject(error);
        }
      });
  
      const gtagRes = await gtagPromise;
      console.log(gtagRes);

      let url = urlWithUtms.href 

      if (store === 'google') {
        url = 'https://play.google.com/store/apps/details?id=com.biblelimits'
      }

      window.open(url, '_blank');
    } else {
      const data = {
        store: store,
        url: urlWithUtms.href,
        gtmEventName: 'download_click',
      };
      createGtmEvent(data);

      window.open(urlWithUtms, '_blank');
    }
  }
  
  return (
    <div style={customStyles ?? {}} className="store-badge-container">
      <div className="download-button" onClick={() => trackStoreClick('apple', appleUrl)}>
        <img height={isMobile ? '40px' : '60px'} width={isMobile ? '120px' : '180px'} className="ios-store-badge" src="/images/badges/ios-badge-en.webp" alt="App Store"/>
      </div>
      <div className="download-button" onClick={() => trackStoreClick('google', googleUrl)}>
        <img height={isMobile ? '60px' : '90px'} width={isMobile ? '155px' : '232.56px'} className="google-store-badge" src="/images/badges/google-play-badge.webp" alt="Google Play Store"/>
      </div>
    </div>
  );
};

export default StoreBadges;
