import GroupSignupButton from '../components/GroupSignupButton';
import '../css/homeA.css';

export default function GroupA({ isMobile, t, isLoggedIn }) {
  return (
    <>
      <div className="home-header-container">
        <div className="home-header-subcontainer">
          <div className="home-header-text-container">
            <h1 className="home-header">{t('header')}</h1>
            
            <span className="group-slogan-desktop"
              dangerouslySetInnerHTML={{ 
                __html: t('subheader') 
              }} 
            />
          </div>

          <img
              width="350px"
              height='350px'
              className="devil-phone"
              src='/images/home/churchfam.webp'
              alt="Devil Phone Picture"
              />
        </div>
        <GroupSignupButton isLoggedIn={isLoggedIn} />
      </div>
      <div className="desktop-top">

        <div className="desktop-image-text-container home-background-fill">
          <div className="outcome-desktop-border">
            <img
              width="200px"
              height="200px"
              className="outcome"
              src='/images/home/church2.png'
              alt="Plan Phone Picture"
            />
          </div>
          <span className="sb7 sb7-desktop">
            {t('problem')}
          </span>
        </div>

        <div className="desktop-image-text-container home-background-fill">
          <span className="sb7 sb7-desktop" style={{paddingRight: 20}}
            dangerouslySetInnerHTML={{ 
              __html: t('plan') 
            }} 
          />
          <div className="outcome-desktop-border">
            <img
              width="200px"
              height="200px"
              className="outcome"
              src='/images/group/feature1.png'
              alt="Plan Phone Picture"
            />
          </div>
        </div>

        <div className="desktop-image-text-container home-background-fill">
          <div className="outcome-desktop-border">
            <img
              width="200px"
              height="200px"
              className="outcome"
              src='/images/home/outcome.webp'
              alt="Outcome Picture"
              />
          </div>
          <span className="sb7 sb7-desktop">
            {t('outcomeDesktop')}
          </span>

        </div>
      </div>
    </>
  );
}