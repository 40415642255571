import React, { useEffect, useState } from 'react';
import '../css/group.css';
import { useTranslation } from 'react-i18next';
import { useUser } from '../store/user-context';

const GroupSignupButton = ({ isLoggedIn, parentEmail }) => {
  const { t } = useTranslation('groupSignupCTA');
  const [cookies, setCookies] = useState(null);
  const { setGroupOwner } = useUser();

  useEffect(() => {
    async function fetchCookies() {
      const consent = await localStorage.getItem('cookieConsent');
      setCookies(consent);
    }
    fetchCookies();
  }, []);

  function trackGroupButtonClick() {
    
    if (cookies === 'accepted' && window.gtag) {
      window.gtag('event', 'group_signup_click', {
        'event_category': 'User',
      });
    }
    
    goToSignup();
  }

  const goToSignup = () => {
    if (isLoggedIn) {
      // We'll need to create a groupOwner from the parent user
      setGroupOwner({ parentEmail })
      
      window.location.href = '/group-memberships';
      return;
    }
    window.location.href = '/group-signup';
  }

  return (
    <button
      className="group-signup-button"
      onClick={() => trackGroupButtonClick()}
    >
      <img width="40px" className="footer-logo" src='/images/logo/logo-small.webp' alt="BibleLimits Logo" />
      <span className='group-signup-button-label-container'>
        <span className='group-signup-button-label-small'>
          {t('buttonSmall')}
        </span>
        <span className='group-signup-button-label'>
          {t('button')}
        </span>
      </span>
    </button>
  );
};

export default GroupSignupButton;
