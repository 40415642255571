import React, { useState, useEffect } from 'react';
import { Element } from 'react-scroll';
import { fadeInLeft } from 'react-animations';
import { keyframes } from 'styled-components';
import styled from 'styled-components';
import '../css/animations.css';
import '../css/home.css';
import { useUser } from '../store/user-context';
import IphoneMockup from '../components/IphoneMockup';
import { useTranslation } from 'react-i18next';
import QuizSlider from '../components/QuizSlider';
import NewsletterSignup from '../components/NewsletterSignup';
import GroupSignupCTA from '../components/GroupSignupCTA';
import GroupExplanation from '../components/GroupExplanation';
import GroupSignupButton from '../components/GroupSignupButton';
import GroupA from './GroupA';

export const BottomWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  padding: 0rem;
  padding-bottom: 0px;
  background-color: white;
  width: 100%;
  margin-top: 0px;
  position: relative;

  @media screen and (max-width: 768px) {
    width: auto;
    // padding: 2rem;
    padding-bottom: 0;
    border-radius: 0;
  }
`;

const SubWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: space-between;
  justify-content: flex-start;
  padding: 5rem;
  background-color: white;
  margin: 20px;
  margin-bottom: 40px;
  margin-top: 40px;
  width: 700px;
  border-radius: 15px;
  position: relative;
  z-index: 50;
  // border: 1px solid #cccccc;

  @media screen and (max-width: 980px) {
    width: auto;
  }

  @media screen and (max-width: 768px) {
    width: auto;
    padding: 2.5rem;
    border-radius: 15px;
  }
`;

const Heading = styled.h1`
  font-family: 'Roboto-Bold', arial, sans-serif;
  font-size: 6rem;
  margin: 0;
  margin-top: 1rem;
  // margin-bottom: .5rem;
  text-align: center;
  color: #373D3F;

  @media screen and (max-width: 768px) {
    font-size: 3rem;
    border-radius: 0;
    padding-left: 20px;
    padding-right: 20px;
  }
`;

const HomeContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  min-height: 80vh;
  background-color: #04a4ee;
  max-width: 100vw;
  overflow-x: hidden;

  @media screen and (max-width: 768px) {
    padding: 0;
    padding-bottom: 20px;
    border-radius: 0;
  }
`

const WaveMainContainer = styled.div`
  height: auto;
  width: 80%;
  position: relative;
  z-index: 100;
  top: 0px;

  @media screen and (max-width: 768px) {
    width: 200%;
    top: 0px;
  }
`;

const WaveSecondaryContainer = styled.div`
  height: auto;
  width: 100%;
  position: relative;
  top: 0px;
  // background-color: red;
  z-index: 100;

  @media screen and (max-width: 768px) {
    width: 200%;
    bottom: -20px;
  }
`;

const WaveBottomContainer = styled.div`
  height: auto;
  background-color: #E0F7FA;
  width: 100%;

  @media screen and (max-width: 768px) {
    width: 200%;
  }
`;

const WaveContainerStyled = styled.div`
  position: relative;
  top: 10px;
  width: 400%;
  left: 20%;
  transition: transform 1.5s ease-out;
  transform: ${(props) => props.transformValue || "translate(-20%, 0%) translate3d(0px, 0px, 0px)"};

  @media screen and (min-width: 768px) {
    width: 500%;
  }

  // if tablet
  @media screen and (min-width: 1200px) {
    width: 400%;
  }
`;

const FadeInLeftAnimation = keyframes`${fadeInLeft}`;

const AnimatedSubWrapper = styled(SubWrapper)`
  animation: 1s ${FadeInLeftAnimation};
`;

export default function Group() {
  const isMobile = window.innerWidth <= 768;
  const [showFirstSubWrapper, setShowFirstSubWrapper] = useState(false);
  const [showSecondSubWrapper, setShowSecondSubWrapper] = useState(false);
  const [transformValue, setTransformValue] = useState("translate(-60%, 0%) translate3d(0px, 0px, 0px)");
  const { t } = useTranslation('groupLandingPage');
  const { state } = useUser();
  const { parent, isLoggedIn } = state;
  
  useEffect(() => {
    const handleScroll = () => {
      const firstSubWrapperElement = document.querySelector('[id="firstSubWrapper"]');
      let firstSubWrapperTop = firstSubWrapperElement ? firstSubWrapperElement.getBoundingClientRect().top : null;
      const screenHeight = window.innerHeight;

      const scrollY = window.scrollY;
      const newTransformValue = someInterpolationFunction(scrollY);
      setTransformValue(newTransformValue);

      if (firstSubWrapperTop !== null && firstSubWrapperTop <= screenHeight + scrollY) {
        setShowFirstSubWrapper(true);
        firstSubWrapperTop = null;
      }
    };

    // Only add listener if wave-start is on the page
    if (document.querySelector('.wave-start')) {
      window.addEventListener('scroll', handleScroll);
    }

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);
  
  const someInterpolationFunction = (scrollY) => {
    const minScroll = 0;
  
    if (!document.body) return;
    
    const maxScroll = document.body.scrollHeight - window.innerHeight;
    
    let minTransform = -75;
    if (isMobile) {
      minTransform = -75;
    }
    const maxTransform = 0;
    
    const normalizedScroll = (scrollY - minScroll) / (maxScroll - minScroll);
    const transformValue = minTransform + normalizedScroll * (maxTransform - minTransform);
    
    return `translate(${transformValue}%, 0%) translate3d(0px, 0px, 0px)`;
  };
  
  return (
    <HomeContainer>
      <div className='home-wrapper'>
        {
          isMobile ? (
            <>
              <Heading>{t('header')}</Heading>
              <span className="slogan"
                dangerouslySetInnerHTML={{ 
                  __html: t('subheader') 
                }} 
              />

              <GroupSignupButton isLoggedIn={isLoggedIn} />
              <div className="desktop-mockups">

              <div className="home-gradient" style={{marginTop: 30}}>
                <span className="sb7">
                  {t('problem')}
                </span>
                </div>

                <span className="home-image-container-mobile"  style={{marginTop: 30}}>
                  <img
                    src='/images/home/churchfam.webp'
                    height="250px"
                    alt="Devil Phone Picture"
                    loading="eager"
                  />
                </span>

                <div className="home-mobile-text-container mt-20 mb-20">
                  <span className="sb7">
                    {t('plan')}
                  </span>
                </div>

                <span className="home-image-container-mobile">
                  <img
                    // className="outcome-mobile"
                    height="250px"
                    src='/images/group/feature1.png'
                    alt="Outcome Phone Picture"
                    loading='lazy'
                  />
                </span>

                <div className="home-mobile-text-container" style={{marginBottom: '60px', marginTop: '30px'}}>
                  <span className="sb7 pt-10 pb-10">{
                    t('outcome')}
                  </span>
                </div>

              </div>
            </>
          ) : (
            <GroupA
              t={t}
              isLoggedIn={isLoggedIn}
              isMobile={isMobile}
            />
          )
        }
        <WaveMainContainer className="wave-start">
          
          <WaveContainerStyled transformValue={transformValue}>
            <div className="wave">
              <svg viewBox="0 0 4930 306" xmlns="http://www.w3.org/2000/svg" preserveAspectRatio="none">
                <path d="M4602.5 178.024C4863 184 5062.27 95.4292 5138 42.1455V302H-334V42.1438C-282.61 66.1213 -146.17 128.071 -11.49 148.05C143.87 178.024 285.08 153.646 410.6 106.586C535.86 59.6268 682.92 33.6528 792.45 60.1264C1184.18 165.111 1457.24 -38.4748 1850 23C1965 41 2141.84 94.0971 2279.77 134.066C2417.7 166.035 2466.38 178.024 2588.09 178.024C2709.79 178.024 2762.17 164.554 2888.8 129.568C2989.71 98.0936 3373.3 -70.7574 3778.98 42.1455C4184.65 150.048 4292.62 170.916 4602.5 178.024Z" fill="#04a4ee"></path>
              </svg>
            </div>
          </WaveContainerStyled>

        </WaveMainContainer>
      
      </div>

      <GroupExplanation />

      <Element id="firstSubWrapper" style={{margin: 0}}>
        {showFirstSubWrapper ? (
          <AnimatedSubWrapper>
            <GroupSignupCTA isLoggedIn={isLoggedIn} />
          </AnimatedSubWrapper>
        ) : (
          <div className='no-content'>
          </div>
        )}
        <IphoneMockup />
      </Element>

      <NewsletterSignup />

      <WaveSecondaryContainer>
        <WaveContainerStyled transformValue={transformValue}>
          <div className="wave">
            <svg viewBox="0 0 4930 306" xmlns="http://www.w3.org/2000/svg" preserveAspectRatio="none">
              <path d="M4602.5 178.024C4863 184 5062.27 95.4292 5138 42.1455V302H-334V42.1438C-282.61 66.1213 -146.17 128.071 -11.49 148.05C143.87 178.024 285.08 153.646 410.6 106.586C535.86 59.6268 682.92 33.6528 792.45 60.1264C1184.18 165.111 1457.24 -38.4748 1850 23C1965 41 2141.84 94.0971 2279.77 134.066C2417.7 166.035 2466.38 178.024 2588.09 178.024C2709.79 178.024 2762.17 164.554 2888.8 129.568C2989.71 98.0936 3373.3 -70.7574 3778.98 42.1455C4184.65 150.048 4292.62 170.916 4602.5 178.024Z" fill="#E0F7FA"></path>
            </svg>
          </div>
        </WaveContainerStyled>
      </WaveSecondaryContainer>
      
      <QuizSlider />

      <BottomWrapper>
        <WaveBottomContainer>
          <WaveContainerStyled>
            <div className="wave">
              <svg viewBox="0 0 4930 306" xmlns="http://www.w3.org/2000/svg" preserveAspectRatio="none">
                <path d="M4602.5 178.024C4863 184 5062.27 95.4292 5138 42.1455V302H-334V42.1438C-282.61 66.1213 -146.17 128.071 -11.49 148.05C143.87 178.024 285.08 153.646 410.6 106.586C535.86 59.6268 682.92 33.6528 792.45 60.1264C1184.18 165.111 1457.24 -38.4748 1850 23C1965 41 2141.84 94.0971 2279.77 134.066C2417.7 166.035 2466.38 178.024 2588.09 178.024C2709.79 178.024 2762.17 164.554 2888.8 129.568C2989.71 98.0936 3373.3 -70.7574 3778.98 42.1455C4184.65 150.048 4292.62 170.916 4602.5 178.024Z" fill="white"></path>
              </svg>
            </div>
          </WaveContainerStyled>
        </WaveBottomContainer>
        <div style={{ paddingBottom: '200px', maxWidth: '800px' }}>
          <GroupSignupCTA alternate={true} isLoggedIn={isLoggedIn} />
        </div>
      </BottomWrapper>
    </HomeContainer>
  );
}
