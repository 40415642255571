import React from 'react';
import { useTranslation } from 'react-i18next';
import StoreBadges from './StoreBadges';
import '../css/home-cta.css';

export const HomeCTA = () => {
  const { t } = useTranslation('homeCTA');

  return (
    <div className='fade-in cta-container'>
      <span className="cta-title"
          dangerouslySetInnerHTML={{ 
            __html: t('header') 
          }} 
        />
      <span className='cta-subtitle'>{t('subheader')}</span>
      <span className='cta-text'>{t('subheader2')}</span>
      <StoreBadges />
    </div>
  );
};

export default HomeCTA;
