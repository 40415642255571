// Using font awesome icons, link to social media accounts (facebook, instagram, twitter)


import React from 'react'
import '../css/step-styles.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faFacebook, faInstagram, faTwitter } from '@fortawesome/free-brands-svg-icons';

export default () => {
  const size = '2x';
  return (
    <div style={{marginTop: 20, display: 'block'}}>
      <a title="Facebook Page" aria-label="Visit our Facebook page" style={{padding: 7}} href="https://www.facebook.com/profile.php?id=61552501048297" target="_blank" rel="noopener noreferrer" tabIndex="0"><FontAwesomeIcon color={'white'} size={size} icon={faFacebook} /></a>
      <a title="Instagram Page" aria-label="Visit our Instagram page" style={{padding: 7}} href="https://www.instagram.com/biblelimits" target="_blank" rel="noopener noreferrer" tabIndex="0"><FontAwesomeIcon color={'white'} size={size} icon={faInstagram} /></a>
      <a title="Twitter Page" aria-label="Visit our Twitter page" style={{padding: 7}} href="https://twitter.com/BibleLimits" target="_blank" rel="noopener noreferrer" tabIndex="0"><FontAwesomeIcon color={'white'} size={size} icon={faTwitter} /></a>
    </div>
  )
}
