import React, { useState } from 'react';
import '../css/cookie-popup.css';
import { useTranslation } from 'react-i18next';

const CookiePopup = ({ onAccept }) => {
  const { t } = useTranslation();
  const [visible, setVisible] = useState(true);

  const handleAccept = () => {
    // Store consent to cookies in local storage or elsewhere
    localStorage.setItem('cookieConsent', 'accepted');
    setVisible(false);
    onAccept();
  };

  // Check if consent has already been given
  if (localStorage.getItem('cookieConsent') === 'accepted') {
    return null;
  }

  return (
    visible && (
      <div className="cookie-popup">
        <p>
          {t('cookiePopup:description')}
          <a href="https://app.websitepolicies.com/policies/view/yxwugdb0" target="_blank" rel="noopener noreferrer">
            {t('cookiePopup:learnMore')}
          </a>
        </p>
        <div className="cookie-button-container">
          <button className="non-essential-button" onClick={handleAccept}>{t('cookiePopup:rejectNonEssential')}</button>
          <button onClick={handleAccept}>{t('cookiePopup:accept')}</button>
        </div>
      </div>
    )
  );
};

export default CookiePopup;
