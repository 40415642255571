import React from 'react';
import StoreBadges from '../components/StoreBadges';
import YoutubeEmbed from '../components/YoutubeEmbed';
import '../css/homeA.css';
import PastorEndorsement from '../components/PastorEndorsement';
import DonationHomeInfo from '../components/DonationHomeInfo';

export default function HomeAMobile({ isMobile, t }) {
  return (
    <>
      <div className="mobile-above-the-fold">
        <div className="mobile-top">

          <h1 className="mobile-heading">{t('header')}</h1>
          <span className="slogan"
            dangerouslySetInnerHTML={{ 
              __html: t('subheader') 
            }} 
            />
          <StoreBadges />
          <PastorEndorsement />
          {/* <DonationHomeInfo /> */}
        </div>

        <YoutubeEmbed embedId="WJlcACD3LHs" />
      </div>

      <div className="desktop-mockups">

      <div className="home-gradient">
        <span className="sb7 problem-text-mobile">
          {t('problem')}
        </span>
      </div>

      <span className="home-image-container-mobile">
        <img
          className="devil-phone"
          src='/images/home/devil-phone-mobile.webp'
          alt="Devil Phone Picture"
          loading="eager"
        />
      </span>

      <div className="home-mobile-text-container">
        <span className="sb7 plan-text-mobile"
          dangerouslySetInnerHTML={{ 
            __html: t('plan') 
          }} 
        />
      </div>

      <span className="home-image-container-mobile outcome-container-mobile">
        <img
          // className="outcome-mobile"
          height="250px"
          width="250px"
          src='/images/home/churchfam.webp'
          alt="Outcome Phone Picture"
          loading='lazy'
        />
      </span>

      <div className="home-mobile-text-container" style={{marginBottom: '60px', marginTop: '30px', fontStyle: 'italic', fontWeight: '700'}}>
        <span className="sb7 outcome-text-mobile">{
          t('outcome')}
        </span>
      </div>

      </div>
    </>
  );
}