import React, { useState, useEffect } from 'react';
import { Element } from 'react-scroll';
import { fadeInLeft } from 'react-animations';
import { keyframes } from 'styled-components';
import styled from 'styled-components';
import '../css/animations.css';
import '../css/home.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBookBible, faClock, faFaceSmile, faGraduationCap, faInfo, faUnlock } from '@fortawesome/free-solid-svg-icons';
import StoreBadges from '../components/StoreBadges';
import IphoneMockup from '../components/IphoneMockup';
import { useTranslation } from 'react-i18next';
import AppleOnlyBadge from '../components/AppleOnlyBadge';
import QuizSlider from '../components/QuizSlider';
import HomeCTA from '../components/HomeCTA';
import NewsletterSignup from '../components/NewsletterSignup';
import HomeB from './HomeB';
import HomeA from './HomeA';
import HomeAMobile from './HomeAMobile';
import HomeBMobile from './HomeBMobile';

export const BottomWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  padding: 0rem;
  padding-bottom: 0px;
  background-color: white;
  width: 100%;
  margin-top: 0px;
  position: relative;

  @media screen and (max-width: 768px) {
    width: auto;
    // padding: 2rem;
    padding-bottom: 0;
    border-radius: 0;
  }
`;

const SubWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: space-between;
  justify-content: flex-start;
  padding: 5rem;
  background-color: white;
  margin: 20px;
  margin-bottom: 40px;
  margin-top: 40px;
  width: 700px;
  border-radius: 15px;
  position: relative;
  z-index: 50;
  // border: 1px solid #cccccc;

  @media screen and (max-width: 980px) {
    width: auto;
  }

  @media screen and (max-width: 768px) {
    padding: 2.5rem;
    border-radius: 15px;
  }
`;

const ListContent = styled.p`
  font-size: 1.5rem;
  line-height: 1.5;
  text-align: left;
  color: #373D3F;
  text-align: left;
  margin-top: 1rem;
  margin-bottom: 1rem;
  max-width: 75%;
  
  @media screen and (max-width: 768px) {
    max-width: 70%;
    margin-left: 20px;
    font-size: 1.2rem;
  }
`;

const SubContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 100%;
`;

const HomeContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  min-height: 80vh;
  background-color: #04a4ee;
  max-width: 100vw;
  overflow-x: hidden;

  @media screen and (max-width: 768px) {
    padding: 0;
    padding-bottom: 20px;
    border-radius: 0;
  }
`

const WaveMainContainer = styled.div`
  height: auto;
  width: 80%;
  position: relative;
  z-index: 100;
  top: 0px;

  @media screen and (max-width: 768px) {
    width: 200%;
    top: 0px;
  }
`;

const WaveSecondaryContainer = styled.div`
  height: auto;
  width: 80%;
  position: relative;
  top: 0px;
  // background-color: red;
  z-index: 100;

  @media screen and (max-width: 768px) {
    width: 200%;
    bottom: -20px;
  }
`;

const WaveBottomContainer = styled.div`
  height: auto;
  background-color: #E0F7FA;
  width: 100%;

  @media screen and (max-width: 768px) {
    // top: -15%;
    width: 200%;
  }
`;

const WaveContainerStyled = styled.div`
  position: relative;
  top: 10px;
  width: 400%;
  left: 20%;
  transition: transform 1.5s ease-out;
  transform: ${(props) => props.transformValue || "translate(-20%, 0%) translate3d(0px, 0px, 0px)"};

  @media screen and (min-width: 768px) {
    width: 500%;
  }

  // if tablet
  @media screen and (min-width: 1200px) {
    width: 400%;
  }
`;

const FadeInLeftAnimation = keyframes`${fadeInLeft}`;

const AnimatedSubWrapper = styled(SubWrapper)`
  animation: 1s ${FadeInLeftAnimation};
`;

export default function Home() {
  const isMobile = window.innerWidth <= 768;
  const [showFirstSubWrapper, setShowFirstSubWrapper] = useState(false);
  const [transformValue, setTransformValue] = useState("translate(-60%, 0%) translate3d(0px, 0px, 0px)");
  const { t } = useTranslation('home');
  
  useEffect(() => {
    const handleScroll = () => {
      const firstSubWrapperElement = document.querySelector('[id="firstSubWrapper"]');
      let firstSubWrapperTop = firstSubWrapperElement ? firstSubWrapperElement.getBoundingClientRect().top : null;
      const screenHeight = window.innerHeight;

      const scrollY = window.scrollY;
      const newTransformValue = someInterpolationFunction(scrollY);
      setTransformValue(newTransformValue);

      // Change outcome-mobile.webp on mobile to outcome.webp, if the user has scrolled to 100px down 
      if (isMobile && scrollY >= 200 && !showFirstSubWrapper) {
        const outcomeMobile = document.querySelector('.outcome-mobile');
        if (outcomeMobile && outcomeMobile.src.includes('outcome-mobile.webp')) {
          outcomeMobile.src = '/images/home/outcome.webp';
        }
      }
      if (firstSubWrapperTop !== null && firstSubWrapperTop <= screenHeight + scrollY) {
        setShowFirstSubWrapper(true);
        firstSubWrapperTop = null;
      }
    };

    // Only add listener if wave-start is on the page
    if (document.querySelector('.wave-start')) {
      window.addEventListener('scroll', handleScroll);
    }

    // Get the current URL
    let url = new URL(window.location.href);

    // Get the current search parameters
    let params = new URLSearchParams(url.search);

    // Add the userVariation to the utm_content parameter
    params.set('utm_content', `${userVariation}`);

    // Update the search parameters of the URL
    url.search = params.toString();

    // Update the URL in the browser without reloading the page
    window.history.replaceState({}, '', url.toString());

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);
  
  const someInterpolationFunction = (scrollY) => {
    const minScroll = 0;
  
    if (!document.body) return;
    
    const maxScroll = document.body.scrollHeight - window.innerHeight;
    
    let minTransform = -75;
    if (isMobile) {
      minTransform = -75;
    }
    const maxTransform = 0;
    
    const normalizedScroll = (scrollY - minScroll) / (maxScroll - minScroll);
    const transformValue = minTransform + normalizedScroll * (maxTransform - minTransform);
    
    return `translate(${transformValue}%, 0%) translate3d(0px, 0px, 0px)`;
  };

  function getVariation() {
    // check if the url already has a variation
    const url = new URL(window.location.href);
    const params = new URLSearchParams(url.search);
    const variation = params.get('utm_content');

    if (variation) {
      return variation;
    }

    return 'variant_b'
    // Check if the variation is already in local storage
    const cachedVariation = localStorage.getItem('userVariation');
  
    if (cachedVariation) {
      // If it is, return the cached variation
      return cachedVariation;
    } else {
      // If it's not, generate a new variation
      const newVariation = Math.random() < 0.5 ? 'variant_a' : 'variant_b';
  
      // Store the new variation in local storage
      localStorage.setItem('userVariation', newVariation);
  
      return newVariation;
    }
  }

  const [userVariation] = useState(getVariation());
  
  return (
    <HomeContainer>
      <div className='home-wrapper'>
        {
          isMobile ? (
            {
              variant_a: <HomeAMobile isMobile={isMobile} t={t} />,
              variant_b: <HomeBMobile isMobile={isMobile} t={t} />
            }[userVariation]
          ) : (
            {
              variant_a: <HomeA isMobile={isMobile} t={t} />,
              variant_b: <HomeB isMobile={isMobile} t={t} />
            }[userVariation]
          )
        }
      <WaveMainContainer className="wave-start">
  
        <WaveContainerStyled transformValue={transformValue}>
          <div className="wave">
            <svg viewBox="0 0 4930 306" xmlns="http://www.w3.org/2000/svg" preserveAspectRatio="none">
              <path d="M4602.5 178.024C4863 184 5062.27 95.4292 5138 42.1455V302H-334V42.1438C-282.61 66.1213 -146.17 128.071 -11.49 148.05C143.87 178.024 285.08 153.646 410.6 106.586C535.86 59.6268 682.92 33.6528 792.45 60.1264C1184.18 165.111 1457.24 -38.4748 1850 23C1965 41 2141.84 94.0971 2279.77 134.066C2417.7 166.035 2466.38 178.024 2588.09 178.024C2709.79 178.024 2762.17 164.554 2888.8 129.568C2989.71 98.0936 3373.3 -70.7574 3778.98 42.1455C4184.65 150.048 4292.62 170.916 4602.5 178.024Z" fill="#04a4ee"></path>
            </svg>
          </div>
        </WaveContainerStyled>

      </WaveMainContainer>
      </div>
      
      <Element id="firstSubWrapper" style={{marginTop: 0}}>
        {showFirstSubWrapper ? (
          <AnimatedSubWrapper style={{marginTop: 0}}>
            <HomeCTA />
          </AnimatedSubWrapper>
        ) : (
          <div className='no-content'>
          </div>
        )}
        <IphoneMockup isScrolled={showFirstSubWrapper} />
      </Element>

      <Element id="secondSubWrapper">
        <SubWrapper>
          <h2 className='home-subheading'>
            {t('howItWorks')}<br/>{t('subheader2')}
          </h2>
          <SubContainer>
            <div className="icon-container">
              <FontAwesomeIcon icon={faBookBible} size={'3x'} color={'#F4BB44'} />
            </div>
            <ListContent>
              {t('howText1')}
            </ListContent>
          </SubContainer>
          <SubContainer>
            <div className="icon-container">
              <FontAwesomeIcon icon={faGraduationCap} size={'3x'} color={'#F4BB44'} />
            </div>
            <ListContent>
              {t('howText2')}
            </ListContent>
          </SubContainer>
          <SubContainer>
            <div className="icon-container">
              <FontAwesomeIcon icon={faFaceSmile} size={'3x'} color={'#F4BB44'} />
            </div>
            <ListContent>
              {t('howText3')}
            </ListContent>
          </SubContainer>
          <SubContainer>
            <div className="icon-container">
              <FontAwesomeIcon icon={faUnlock} size={'3x'} color={'#F4BB44'} />
            </div>
            <ListContent>
              {t('howText4')}
              <AppleOnlyBadge size={'1x'} />
            </ListContent>
          </SubContainer>
        </SubWrapper>
      </Element>

      <NewsletterSignup />

      <WaveSecondaryContainer>
        <WaveContainerStyled transformValue={transformValue}>
          <div className="wave">
            <svg viewBox="0 0 4930 306" xmlns="http://www.w3.org/2000/svg" preserveAspectRatio="none">
              <path d="M4602.5 178.024C4863 184 5062.27 95.4292 5138 42.1455V302H-334V42.1438C-282.61 66.1213 -146.17 128.071 -11.49 148.05C143.87 178.024 285.08 153.646 410.6 106.586C535.86 59.6268 682.92 33.6528 792.45 60.1264C1184.18 165.111 1457.24 -38.4748 1850 23C1965 41 2141.84 94.0971 2279.77 134.066C2417.7 166.035 2466.38 178.024 2588.09 178.024C2709.79 178.024 2762.17 164.554 2888.8 129.568C2989.71 98.0936 3373.3 -70.7574 3778.98 42.1455C4184.65 150.048 4292.62 170.916 4602.5 178.024Z" fill="#E0F7FA"></path>
            </svg>
          </div>
        </WaveContainerStyled>
      </WaveSecondaryContainer>
      
      <QuizSlider />

      <BottomWrapper>
        <WaveBottomContainer>
          <WaveContainerStyled>
            <div className="wave">
              <svg viewBox="0 0 4930 306" xmlns="http://www.w3.org/2000/svg" preserveAspectRatio="none">
                <path d="M4602.5 178.024C4863 184 5062.27 95.4292 5138 42.1455V302H-334V42.1438C-282.61 66.1213 -146.17 128.071 -11.49 148.05C143.87 178.024 285.08 153.646 410.6 106.586C535.86 59.6268 682.92 33.6528 792.45 60.1264C1184.18 165.111 1457.24 -38.4748 1850 23C1965 41 2141.84 94.0971 2279.77 134.066C2417.7 166.035 2466.38 178.024 2588.09 178.024C2709.79 178.024 2762.17 164.554 2888.8 129.568C2989.71 98.0936 3373.3 -70.7574 3778.98 42.1455C4184.65 150.048 4292.62 170.916 4602.5 178.024Z" fill="white"></path>
              </svg>
            </div>
          </WaveContainerStyled>
        </WaveBottomContainer>
        <Element name="thirdSubWrapper" className="no-mp-top">
          <SubWrapper className="no-mp-top padding-overwrite">
            <h2 className='second-cta-header'>
              {t('subheader3')}<br/><strong>{t('subheader4')}</strong>
            </h2>
            <span className="mt-15 mb-15" />
            <StoreBadges />
          </SubWrapper>
        </Element>
      </BottomWrapper>
    </HomeContainer>
  );
}
