import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import '../css/apple-badge.css'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faApple } from '@fortawesome/free-brands-svg-icons';

const AppleOnlyBadge = ({ size }) => {
  const { t } = useTranslation();

  return (
    <span className='apple-badge-container'>
      <span className='apple-badge-subcontainer'>
        <FontAwesomeIcon icon={faApple} size={size ? size : '1x'} color="white" />
        <span className='apple-badge-text'>{t('appleOnly:appleOnly')}</span>
      </span>
    </span>
  );
};

export default AppleOnlyBadge;
