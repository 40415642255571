import { useUser } from '../store/user-context';
import { Navigate } from 'react-router-dom';

export const PrivateRoute = ({ element }) => {
  const { state } = useUser();
  let { isLoggedIn, parent } = state;

  // isLoggedIn = true;

  return isLoggedIn ? element : <Navigate to="/login" replace />
};
