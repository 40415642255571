import { v4 as uuidv4 } from 'uuid';
import { callApi } from '../services/general-service';
import { API_URL } from '../config';

export function getClientId() {
  let clientId = localStorage.getItem('clientID');
  if (!clientId) {
    // Generate a UUID or a random string suitable for the clientID
    clientId = uuidv4();
    localStorage.setItem('clientID', clientId);
  }
  return clientId;
}

export function getUserId() {
  let userId = localStorage.getItem('userID');

  if (!userId) {
    return false
  }
  return userId;
}

export function setUserId(userId) {
  localStorage.setItem('userID', userId);
}

function parseUTMParams(url) {
  const utmParams = {};
  const queryString = url.split('?')[1];
  if (queryString) {
    const params = new URLSearchParams(queryString);
    for (const [key, value] of params) {
      if (key.startsWith('utm_')) {
        utmParams[key] = value;
      }
    }
  }
  return utmParams;
}

export const createGtmEvent = async (eventData) => {
  if (eventData.url) {
    const utmParams = parseUTMParams(eventData.url);
    eventData.utmSource = utmParams.utm_source ?? 'direct';
    eventData.utmMedium = utmParams.utm_medium ?? 'none';
  }

  const clientId = getClientId();
  const userId = getUserId();

  const payload = {
    clientId: clientId,
    ...userId && { userId: userId },
    gtmEventName: eventData.gtmEventName,
    eventData: {
      ...eventData,
    },
  };

  try {
    const response = await callApi(`${API_URL}/create-gtm-event`, "POST", payload);
    return response;
  } catch (error) {
    console.error('createGtmEvent Error:', error);
  }
}
