import DonationHomeInfo from '../components/DonationHomeInfo';
import PastorEndorsement from '../components/PastorEndorsement';
import StoreBadges from '../components/StoreBadges';
import YoutubeEmbed from '../components/YoutubeEmbed';
import '../css/homeA.css';

export default function HomeA({ isMobile, t }) {
  return (
    <>
    <div className="home-header-container">
      <div className="home-header-subcontainer">
        <div className="home-header-text-container">
          <h1 className="home-header">{t('header')}</h1>
          <span className="slogan-desktop"
            dangerouslySetInnerHTML={{ 
              __html: t('subheaderDesktop') 
            }} 
          />
          <PastorEndorsement />
          {/* <DonationHomeInfo /> */}
        </div>

        <img
            height="400px"
            width="293px"
            className="devil-phone"
            src='/images/home/studyman.webp'
            alt="Devil Phone Picture"
            />
      </div>
      <StoreBadges />
    </div>
    <div className="desktop-top">

      <YoutubeEmbed embedId="WJlcACD3LHs" />
      
      <div className="desktop-image-text-container" style={{marginTop: '70px'}}>
        <img
            width="225px"
            height='261px'
            className="devil-phone"
            src='/images/home/devil-phone.webp'
            alt="Devil Phone Picture"
          />
          <span className="sb7 sb7-desktop">
            {t('problem')}
          </span>
      </div>

      <div className="desktop-image-text-container home-background-fill">
        <span className="sb7 sb7-desktop" style={{paddingRight: 0}}
          dangerouslySetInnerHTML={{ 
            __html: t('plan') 
          }} 
        />
        <img
          width="250px"
          height="250px"
          className="plan"
          src='/images/home/plan.webp'
          alt="Plan Phone Picture"
        />
      </div>

      <div className="desktop-image-text-container home-background-fill">
        <div className="desktop-image-text-container outcome-desktop-border">
          <img
            width="250px"
            height="250px"
            className="outcome"
            src='/images/home/outcome.webp'
            alt="Outcome Picture"
            />
        </div>
        <span className="sb7 sb7-desktop">
          {t('outcomeDesktop')}
        </span>

      </div>
      </div>
    </>
  );
}