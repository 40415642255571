import React from 'react';
import { useTranslation } from 'react-i18next';
import '../css/home-cta.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChurch, faSmile, faUsers } from '@fortawesome/free-solid-svg-icons';

export const GroupExplanation = () => {
  const { t } = useTranslation('groupExplanation');

  return (
    <div className="newsletter-container" style={{marginTop: 0}}>

      <div className='fade-in cta-container' >
        <span className='cta-title'>
          {t('header')}
        </span>
        <div className="explanation-row">
          <FontAwesomeIcon icon={faUsers} color={'#F4BB44'} style={{ marginRight: 20, fontSize: 34 }} />
          <span className='explanation-subtitle'>
            {t('description2')}
          </span>
        </div>
        <div className="explanation-row">
          <FontAwesomeIcon icon={faChurch} color={'#F4BB44'} style={{ marginRight: 20, fontSize: 34 }} />
          <span className='explanation-subtitle'>
            {t('description')}
          </span>
        </div>
        <div className="explanation-row">
          <FontAwesomeIcon icon={faSmile} color={'#F4BB44'} style={{ marginRight: 20, fontSize: 34 }} />
          <span className='explanation-subtitle'>
            {t('description3')}
          </span>
        </div>
      </div>
    </div>
  );
};

export default GroupExplanation;
