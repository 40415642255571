import React from 'react';
import { useTranslation } from 'react-i18next';
import '../css/home-cta.css';
import GroupSignupButton from './GroupSignupButton';

export const GroupSignupCTA = ({ alternate = false, isLoggedIn }) => {
  const { t } = useTranslation('groupSignupCTA');

  return (
    <div className='fade-in cta-container'>
      <span className={ alternate ? 'cta-title group-centered'  : 'cta-title' }>
        {
          alternate ? t('alternateHeader') : t('header')
        }
      </span>
      <span className={ alternate ? 'cta-subtitle group-centered'  : 'cta-subtitle' }>
        {
          alternate ? t('alternateSubheader') : t('subheader')
        }
        </span>
      <span style={{width: '100%', display: 'flex', justifyContent: 'center', marginTop: 20}}>
        <GroupSignupButton isLoggedIn={isLoggedIn} />
      </span>
    </div>
  );
};

export default GroupSignupCTA;
