// src/hooks/usePageView.js
import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

const usePageView = (cookiesAccepted) => {
  const location = useLocation();

  useEffect(() => {
    // if (cookiesAccepted && window.gtag) {
    if (window.gtag) {
      const pagePath = location.pathname + location.search;
      window.gtag('config', 'G-EMT79Z57JS', {
        page_path: pagePath,
      });
    }
  }, [cookiesAccepted, location]);

};

export default usePageView;