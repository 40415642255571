import React from 'react';
import '../css/mockups.css';

const IphoneMockup = ({ customStyles, isScrolled }) => {
  const width = window.innerWidth;

  return (
    <div style={customStyles ?? {}} className="iphone-mockup-container">
      {
        width <= 768 ? (
          <img
            className="iphone-mockup"
            src={ isScrolled ? 'https://d1h45vdk7ivw2w.cloudfront.net/mockup-video.webp' : 'https://d1h45vdk7ivw2w.cloudfront.net/mockup-video.webp' }
            alt="iPhone Mockup"
            loading='lazy'
          />
        )
        : <img width="510px" height="365px" className="iphone-mockup" src='https://d1h45vdk7ivw2w.cloudfront.net/mockup-video.webp' alt="Large iPhone Mockup" />
      }
    </div>
  );
};

export default IphoneMockup;
