export const lightGrey = '#F5F5F5'
export const grey = '#dddddd'
export const darkGrey = '#878787'
export const green = '#83b34b'
// export const blue = '#0884fc'
export const lightBlue = '#04a4ee'
export const blue = '#0484d9'
export const darkBlue = '#0459b0'
export const gold = '#FF944D'
export const darkPurple = '#3333a2'
export const red = '#D94C4C';
export const lightRed = '#FFCCCB'
export const orange = '#F4BB44'
export const yellow = '#FFF8E6'
export const darkBrown = '#763d1c'
export const lightBrown = '#f0ddcc'
export const backgroundBlue = '#0884fc'
export const textColor = '#373D3F'
export const whiteBackground = '#F8F8F8'
export const backgroundColor = '#E0F7FA'
export const googleGreen = '#34A853'
export const brandGreen = '#00BFA5'