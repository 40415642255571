// export const API_URL = 'https://b2icz2qgc2.execute-api.us-west-2.amazonaws.com/Prod';
// export const API_URL = '/api';
export const API_URL =
  process.env.NODE_ENV === 'production'
    ? 'https://b2icz2qgc2.execute-api.us-west-2.amazonaws.com/Prod'
    : '/api';

export const WEB_APP_URL = 'http://localhost:3000';

export const kjVersion = 'King James Version'
export const douayRheimsVersion = 'Douay Rheims'
export const sagradasVersion = 'Sagradas Escrituras'

export const googleClientID = '8066597729-ahd05833tui00i5kml84i6d14vjrttdn.apps.googleusercontent.com'

export const googleTrackingId = 'UA-180211030-1'


export const appleUrl = 'https://apps.apple.com/us/app/biblelimits/id6462471354';
export const googleUrl = 'https://play.google.com/store/apps/details?id=com.biblelimits';

export const appleMarketingUrl1 = 'https://apps.apple.com/us/app/biblelimits/id6462471354?ppid=41491cc8-e4ba-48e7-97d7-3eef321373b2';