import React, { useState } from 'react';
import '../css/newsletter.css';
import { useTranslation } from 'react-i18next';
import { newsletterSignup } from '../services/general-service';

const NewsletterSignup = ({ customStyles }) => {
  const { t } = useTranslation();
  const [email, setEmail] = useState('');
  const [newsletterSuccess, setNewsletterSuccess] = useState(false);
  const [newsletterError, setNewsletterError] = useState(false);
  const [newsletterErrorMessage, setNewsletterErrorMessage] = useState('');
  const [newsletterLoading, setNewsletterLoading] = useState(false);
  const [attemptCount, setAttemptCount] = useState(0);

  const handleNewsletterSignup = async () => {
    if (!email) {
      setNewsletterError(true);
      setNewsletterErrorMessage('Please enter your email address');
      setTimeout(() => {
        setNewsletterError(false);
        setNewsletterErrorMessage('');
      }, 5000);
      return;
    }

    if (!validateEmail(email)) {
      setNewsletterError(true);
      setNewsletterErrorMessage('Please enter a valid email address');
      setTimeout(() => {
        setNewsletterError(false);
        setNewsletterErrorMessage('');
      }, 5000);
      return;
    }

    setAttemptCount((prevAttemptCount) => prevAttemptCount + 1);

    // Check if maximum attempts have been reached
    if (attemptCount >= 5) {
      setNewsletterError(true);
      setNewsletterErrorMessage('Maximum signup attempts reached.');
      return;
    }

    if (newsletterLoading) return;

    setNewsletterLoading(true);
    const signupRes = await newsletterSignup(email);

    setNewsletterLoading(false);

    if (signupRes.success) {
      setNewsletterSuccess(true);
      // the current url with utm params
      let url = new URL(window.location.href);
      trackSignup('newsletter', url);

      setTimeout(() => {
        setNewsletterSuccess(false);
      }, 5000)
    }

    if (!signupRes.success) {
      setNewsletterError(true);
      setNewsletterErrorMessage(signupRes.message);

      setTimeout(() => {
        setNewsletterError(false);
        setNewsletterErrorMessage('');
      }, 5000);
    }
  }

  const validateEmail = (email) => {
    const re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return re.test(email);
  }

  function trackSignup(val, url) {
    window.gtag('event', 'Newsletter Signup', {
      'event_category': 'User',
      'event_label': val,
      'value': url
    });
    window.open(url, '_blank');
  }

  return (
    <div style={customStyles ?? {}} className="newsletter-container">
      <h2 className="newsletter-header">
        {t('newsletter:header')}
      </h2>
      <p className='newsletter-description'>
      {t('newsletter:description')}
      </p>
      {
        newsletterSuccess && (
          <p className="newsletter-success">
            {t('newsletter:success')}
          </p>
        )
      }
      {
        newsletterError && (
          <p className="newsletter-error">
            {newsletterErrorMessage}
          </p>
        )
      }
      {
        newsletterLoading && (
          <p className="newsletter-loading">
            {t('newsletter:loading')}
          </p>
        )
      }

      <div className="newsletter-input-container">
        <input
          type="email"
          className='newsletter-input'
          placeholder={t('newsletter:inputPlaceholder')} 
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          disabled={attemptCount >= 5}
          aria-label="Enter your email"
        />
        <button
          onClick={handleNewsletterSignup}
          disabled={newsletterLoading || attemptCount >= 5}
        >
          {
            newsletterLoading ? t('newsletter:loading') : t('newsletter:button')
          }
        </button>
      </div>
    </div>
  );
};

export default NewsletterSignup;
